.Tracker {
  padding-top: 20px;
  h1 {
    color: #a3230d;
    display: inline-block;
    font-weight: 700;
  }
  .back-to-tracker {
    margin-bottom: 20px;
  }
  p.about {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 22px;
  }
  & > span {
    display: inline-block;
    margin-bottom: 5px;
  }
  .filters {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    column-gap: 20px;
    & > div {
      width: 100%;
    }
    & > div > div {
      width: 100%;
    }
  }
  .table-wrapper {
    [class^='flag:'] {
      margin-right: 5px;
      height: 18px;
      width: calc((18px)*3/2);      
    }    
  }
  .event-date {
    background: #f8f8f8;
    display: inline-block;
    padding: 1px 4px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
    border-radius: 4px;
  }
  .event-actions {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
  .event-text {
    font-size: 16px;
    line-height: 22px;
    a {
      margin: 0 2px;
      white-space: nowrap;
    }
  }
  .event-meta {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    & > div:last-child {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-right: -8px;
      span {
        margin-bottom: 8px;
      }
    }
    .country-icon {
      box-shadow: 0 1px 2px 0px rgb(0, 0, 0, 0.2);
    }   
  }
}
@media screen and (max-width: 800px) {
  .Tracker {
    .filters {
      flex-direction: column;
      & > div + div {
        margin-top: 20px;
        margin-left: 0;
      }
      .ant-select {
        width: 100%;
      }
    }
  }
}