:root {
  --primary-color: #b83737;
  --primary-color-dark: #a3230d;
  --primary-bg-color: #f1dbdb;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  p {
    line-height: 20px;
  }
}

html, body {
  font-family: 'Robot', Arial, Helvetica, sans-serif;
}


button.tws {
  background: white;
  border: 1px solid var(--primary-color);
  box-shadow: 0 2px 1px var(--primary-bg-color);
  padding: 10px 12px;
  border-radius: 4px;
  font-weight: 600;
  &:hover {
    background: var(--primary-bg-color);
  }
}

.tws-label {
  margin-bottom: 6px;
  font-size: 15px;
  display: inline-block;
  font-size: 'Noto Sans';
  color: #6d6d6d;
}

[class^='flag:'] {
  border-radius: 2px;
  overflow: hidden;
}

.ant-select-item-option-content {
  display: flex;
  align-items: center;      
  font-family: 'Roboto', sans-serif;
  div {
    margin-right: 10px;
  }
  span {
    font-weight: 500;
  }
}
.ant-select-selection-overflow-item {
  display: inline-flex;
  align-items: center;
  span {
    display: flex;
    margin: 0 3px;
    div {
      border-radius: 2px;
    }
  }
}


@media screen and (min-width: 601px) {
  .mobile-visible {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .desktop-visible {
    display: none;
  }
}
