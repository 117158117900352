@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,400italic,500,500italic,600,700);

.App {
  width: 100%;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
  overflow: auto;
  background: #eeecec;
  .logo {
    width: 200px;
    margin-left: -40px;
    padding: 10px 10px 10px 20px;
    background: white;
  }
  h1 {
    font-size: 35px;
    font-family: 'Noto Sans';
    font-weight: 600;
  }  
  h2 {
    font-size: 22px;
    font-family: 'Noto Sans';
    font-weight: 600;
  }    
  .bm-menu-wrap {
    background: var(--primary-color);
    padding-top: 40px;
    a {
      color: white;
      text-align: center;
      margin-bottom: 10px;
      padding: 10px;
      font-size: 18px;
      font-weight: 600;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        background: var(--primary-color-dark);
      }
    }
  }
  .bm-cross-button {
    opacity: 0.7;
    box-sizing: content-box;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  .bm-burger-button {
    display: block;
    background: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 10px;
    box-sizing: content-box;
    width: 30px;
    height: 20px;
    position: fixed;
    top: 30px;
    right: 30px;
    border-radius: 10px;
    .bm-burger-bars {
      background: black;
      border-radius: 4px;
      position: relative !important;
      height: 4px !important;
      width: 30px !important;
      display: block !important;
      &:nth-child(1) {
        top: 0 !important;
      }
      &:nth-child(2) {
        top: 4px !important;
      }
      &:nth-child(3) {
        top: 8px !important;
      }
    }
  }
  .bm-cross-button {
    height: 24px;
    width: 24px;
    span {
      background: white;
    }
  }
  #page-wrap {
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px;
    min-height: 90vh;
    background: white url('/assets/images/tweu_collage_v2.png') no-repeat top center;
    background-size: 100%;
  }

  .footer {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    font-size: 14px;
    color: #252525;
    border-top: 2px solid #e2f5e2;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    .copyright {
      font-weight: 500;
    }
    .social {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      a {
        margin-left: 20px;
      }
      svg:hover {
        fill: #da3316;
      }
    }    
  }
}

@media screen and (max-width: 800px) {
  .App {
    .ant-table-cell {
      padding-left: 0;
      padding-right: 0;
    }    
    h1 {
      font-size: 30px;
    }  
    h2 {
      font-size: 18px;
    }    
    #page-wrap {
      padding: 30px;
    }
  }
}