.Analyses {
  padding-top: 50px;
  .analyses-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 20px -20px;
    & > div {
      width: 30%;
      margin-top: 20px;
      margin-bottom: 10px;
      padding: 0 20px;
      .thumbnail {
        width: 100%;
        margin-bottom: 10px;
        height: 200px;
        display: block;
        background: silver;
        overflow: hidden;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit:cover;
        }
      }
      a.title {
        color: #a3230d;
        &:hover {
          color: #da2100;
        }
        font-weight: 500;
        font-size: 20px;
        font-family: 'Noto Sans';
        font-weight: 600;
        margin-bottom: 10px;
        display: inline-block;
        line-height: 26px;  
      }
      p.date {
        color: #9e9e9e;
        font-size: 14px;
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .Analyses {
    .analyses-container {
      margin: 0;
      & > div {
        width: 100%;
        padding: 0;
      }
    }
  }
}