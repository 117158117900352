.About {
  padding-top: 50px;
  .team {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    // margin: 0 -20px;
    & > div {
      width: 50%;
      margin-top: 20px;
      margin-bottom: 10px;
      max-width: 250px;
      padding: 0 20px;
      text-align: center;
      .thumbnail {
        width: 150px;
        margin: 0 auto 10px;
        height: 150px;
        display: block;
        background: silver;
        overflow: hidden;
        border-radius: 50%;
        flex-shrink: 0;
        position: relative;
        img {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 120%;
        }
      }      
      p {
        margin: 0;
      }
      p.name {
        font-weight: 500;
        font-size: 18px;
        font-family: 'Noto Sans';
        font-weight: 600;
        margin-bottom: 5px;        
      }
      p.email {
        color: #258318;
      }
    }
  }
}